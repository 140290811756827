module.exports = {
  siteTitle: 'Steve Englander', // <title>
  manifestName: 'Steve Englander',
  manifestShortName: 'Steve Englander', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Steve Englander',
  photoUrl: 'https://avatars.githubusercontent.com/u/769366?v=4',
  subHeading:
    "Keep in touch with Steve when he goes forever #decentralized",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/confidenti-al',
    },
    // {
    //   icon: 'fa-linkedin',
    //   name: 'LinkedIn',
    //   url: 'https://linkedin.com/in/recsci',
    // },
  ],
};
